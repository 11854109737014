import {
  CheckOutlined,
  PieChartOutlined,
  UnorderedListOutlined,
  FileTextOutlined,
  FileDoneOutlined,
  InboxOutlined,
  FileSyncOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Layout, Menu } from 'antd';
import React, { ReactNode, useEffect, useState } from 'react';
import ForbiddenModal from '@base/ForbiddenModal';
import { Users as UsersIcon } from '@components/icons/users';
import styles from './AppLayout.module.scss';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';
import { useHistory, useLocation } from 'react-router-dom';
import BlogIcon from '@components/icons/menu/blog';
import DestinationsIcon from '@components/icons/menu/destinations';
import { matchPath } from 'react-router';
import HospitalIcon from '@components/icons/menu/hospital';
import SearchIcon from '@components/icons/menu/search';
import TemplateIcon from '@components/icons/menu/template';
import SettingsIcon from '@components/icons/menu/settings';
import LocalStorage from '@base/LocalStorage';
import { Header } from './Header';
import map from 'lodash/map';
import { useSelector } from '@hooks';
import { User } from '@modelTypes/user';
import useActivityTracker from '@hooks/useActivityTracker';

const { Content, Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

interface Props {
  children: ReactNode;
}

function getItem(label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[]): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const links: Record<string, string> = {
  // Dashboard.
  dashboard: '/',

  // Orders.
  orders__all: '/orders/all',
  orders__my: '/orders/my',
  orders__archived: '/orders/archived',
  orders__paid: '/orders/paid',
  orders__closed: '/orders/closed',

  // Tasks.
  tasks__all: '/tasks/all',
  tasks__my: '/tasks/my',

  // Content.
  content__index: '/contents/main',
  'content__content_menu__health-library': '/contents/menu/health-library',
  content__content_menu__treatments: '/contents/menu/treatments',
  content__content_menu__hospitals: '/contents/menu/hospitals',
  content__content_menu__doctors: '/contents/menu/doctors',
  content__content_menu__destinations: '/contents/menu/destinations',
  content__content_menu__blogs: '/contents/menu/blogs',
  content__content_menu__offers: '/contents/menu/offers',

  // Blog (Content).
  blog__articles: '/blog/articles',
  'blog__disease-guides': '/blog/disease-guides',
  'blog__treatment-guides': '/blog/treatment-guides',

  // Destinations
  destinations_menu__countries: '/countries',
  destinations_menu__cities: '/cities',
  destinations_menu__content: '/destinations/content',

  // (Providers) Hospitals (Content).
  services__hospitals_menu__content: '/hospitals/contents',
  services__hospitals_menu__hospitals: '/hospitals',
  services__hospitals_menu__tags: '/tags',
  services__hospitals_menu__certificates: '/certificates',

  services__doctors_menu__content: '/doctors/contents',
  services__doctors_menu__doctors: '/doctors',
  // services__doctors_menu__doctors__refactor: '/refactor-doctors',
  services__doctors_menu__specializations: '/specializations',
  'services__doctors_menu__excluded-words': '/excluded-words',

  // Treatment.
  treatment__programs_menu__folders: '/treatments/programs/folders',
  treatment__programs_menu__discounts: '/treatments/programs/discounts',
  treatment__programs_menu__programs: '/treatments/programs',
  treatment__methods: '/treatments/methods',
  treatment__types_of_care: '/treatments/types-of-care',
  treatment__services_cost: '/treatments/services-cost',

  // Offers
  offers__methods: '/offers/methods',
  offers__types_of_care: '/offers/types-of-care',
  offers__list: '/offers',

  // Search Engine.
  'search_engine__static-tag-cloud': '/search-engine/static-tag-cloud',
  search_engine__playground: '/search-engine/playground',
  'search_engine__medical-fields': '/search-engine/medical-fields',
  search_engine__related: '/search-engine/related',
  search_engine__synonyms: '/search-engine/synonyms',

  // Users.
  users__roles: '/roles',
  users__list: '/users',
  users__authors: '/authors',
  users__vendors: '/vendors/users',

  // Templates.
  templates__emails: '/templates/emails',
  templates__legal: '/templates/legals',

  // Settings.
  configurations__languages__layout: '/frontend-languages/layout',
  configurations__settings: '/settings',
  configurations__languages: '/languages',
  configurations__logs: '/logs',
  configurations__jobs: '/jobs'
};

const items: MenuItem[] = [
  getItem('Dashboard', 'dashboard', <PieChartOutlined />),
  getItem('Orders', 'orders', <UnorderedListOutlined />, [
    getItem('Orders', 'orders__all'),
    getItem('My orders', 'orders__my'),
    getItem('Archived orders', 'orders__archived'),
    getItem('Paid orders', 'orders__paid'),
    getItem('Closed orders', 'orders__closed'),
  ]),
  getItem('Tasks', 'tasks', <CheckOutlined />, [getItem('Task list', 'tasks__all'), getItem('My tasks', 'tasks__my')]),
  getItem('Content', 'content', <FileTextOutlined />, [
    getItem('Main page', 'content__index'),
    getItem('Menu', 'content_menu', null, [
      getItem('Health library', 'content__content_menu__health-library'),
      getItem('Treatments & Packages', 'content__content_menu__treatments'),
      getItem('Hospitals', 'content__content_menu__hospitals'),
      getItem('Doctors', 'content__content_menu__doctors'),
      getItem('Destinations', 'content__content_menu__destinations'),
      getItem('Offers', 'content__content_menu__offers'),
      // getItem('Blogs', 'content__content_menu__blogs'),
    ]),
  ]),
  getItem('Blog', 'blog', <BlogIcon />, [
    getItem('Articles', 'blog__articles'),
    getItem('Disease guides', 'blog__disease-guides'),
    getItem('Treatment guides', 'blog__treatment-guides'),
  ]),
  getItem('Destinations', 'destinations_menu', <DestinationsIcon />, [
    getItem('Countries', 'destinations_menu__countries'),
    getItem('Cities', 'destinations_menu__cities'),
    // getItem('Content', 'destinations_menu__content'),
  ]),
  getItem('Providers', 'services', <HospitalIcon />, [
    getItem('Hospitals', 'hospitals_menu', null, [
      getItem('List', 'services__hospitals_menu__hospitals'),
      // getItem('Content', 'services__hospitals_menu__content'),
      getItem('Tags', 'services__hospitals_menu__tags'),
      getItem('Certificates', 'services__hospitals_menu__certificates'),
    ]),
    getItem('Doctors', 'doctors_menu', null, [
      getItem('List', 'services__doctors_menu__doctors'),
      // getItem('New name', 'services__doctors_menu__doctors__refactor'),
      // getItem('Content', 'services__doctors_menu__content'),
      // getItem('Specializations', 'services__doctors_menu__specializations'),
      // getItem('Exclusion Words', 'services__doctors_menu__excluded-words'),
    ]),
  ]),
  // getItem('Treatments & Packages', 'treatment', <FileDoneOutlined />, [
  //   getItem('Programs', 'programs_menu', null, [
  //     getItem('List', 'treatment__programs_menu__programs'),
  //     getItem('Folders', 'treatment__programs_menu__folders'),
  //     getItem('Discounts', 'treatment__programs_menu__discounts'),
  //   ]),
  //   getItem('Methods', 'treatment__methods'),
  //   getItem('Type of care', 'treatment__types_of_care'),
  //   getItem('Service cost', 'treatment__services_cost'),
  // ]),
  getItem('Offers', 'offers', <InboxOutlined style={{ fontSize: 16 }} />, [
    getItem('List', 'offers__list'),
    getItem('Methods', 'offers__methods'),
    getItem('Type of care', 'offers__types_of_care'),
  ]),
  getItem('Search Engine', 'search_engine', <SearchIcon />, [
    getItem('Static tag cloud', 'search_engine__static-tag-cloud'),
    getItem('Playground', 'search_engine__playground'),
    // getItem('Medical fields', 'search_engine__medical-fields'),
    // getItem('Related', 'search_engine__related'),
    // getItem('Synonyms', 'search_engine__synonyms'),
  ]),
  getItem('Users', 'users', <UsersIcon />, [
    getItem('Roles', 'users__roles'),
    getItem('Users list', 'users__list'),
    getItem('Vendors', 'users__vendors'),
    getItem('Authors & Team', 'users__authors'),
  ]),
  getItem('Templates', 'templates', <TemplateIcon />, [
    getItem('Emails', 'templates__emails'),
    getItem('Legal', 'templates__legal'),
  ]),
  getItem('Configurations', 'configurations', <SettingsIcon />, [
    getItem('Translations', 'configurations__languages__layout'),
    getItem('Settings', 'configurations__settings'),
    getItem('Languages', 'configurations__languages'),
    // getItem('Logs', 'configurations__logs'),
    getItem('Jobs', 'configurations__jobs'),
  ]),
];

const itemsRootKeys = map(items, 'key');

// const itemsSubKeys = ['hospitals__menu', 'doctors__menu'];

function getMatchedKeys(links: any, pathname: string) {
  const keys: Array<string> = [];

  Object.keys(links).forEach((i) => {
    const item = links[i];

    if (matchPath(pathname, item) && item !== '/') {
      keys.push(i);
    } else if (item === '/' && pathname === '/') {
      keys.push(i);
    }
  });

  return keys;
}

export default function AppLayout({ children }: Props) {
  const history = useHistory();
  const location = useLocation();
  const [openKeys, setOpenKeys] = useState<Array<string>>([]);
  const [selectedKeys, setSelectedKeys] = useState<Array<string>>([]);
  const [collapsed, setCollapsed] = useState(LocalStorage.getObject('leftSidebar', 'isCollapsed', false));
  const [menuItem, setMenuItem] = useState<MenuItem[]>(items);
  const user = useSelector<User>('app.user');
  useActivityTracker();

  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

    if (itemsRootKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const handleMenuItemClick: MenuClickEventHandler = (e) => {
    const link = links[e.key];
    if (link) {
      history.push(link);
    }
  };

  useEffect(() => {
    const foundKeys = getMatchedKeys(links, location.pathname);

    if (foundKeys.length) {
      const rootMenu = foundKeys[0].split('__');

      if (!collapsed) {
        setOpenKeys(rootMenu.slice(0, rootMenu.length - 1));
      }

      setSelectedKeys([foundKeys[0]]);
    }
  }, [links, location, collapsed]);

  useEffect(() => {
    LocalStorage.setObject('leftSidebar', { isCollapsed: collapsed });
  }, [collapsed]);

  useEffect(() => {
    if (user && user.isSuperAdmin) {
      setMenuItem((prevState) => {
        return prevState.map((item: any) => {
          if (item.key === 'configurations' && item.children) {
            if (!item.children.find((child: any) => child.key === 'configurations__logs')) {
              return {
                ...item,
                children: [...item.children, getItem('Logs', 'configurations__logs')],
              };
            }
          }
          return item;
        });
      });
    }
  }, [user]);

  return (
    <Layout className={styles.layout}>
      <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        <div className={styles.logo}>
          {/*{!collapsed &&  <img src="/logo.png" />}*/}
          {/*{collapsed &&  <img src="/logo-short.png" />}*/}
        </div>
        <Menu
          multiple={false}
          theme="dark"
          mode="inline"
          // defaultOpenKeys={defaultOpenKeys}
          // defaultSelectedKeys={defaultSelectedKeys}
          openKeys={openKeys}
          selectedKeys={selectedKeys}
          onOpenChange={onOpenChange}
          items={menuItem}
          onClick={handleMenuItemClick}
        />
      </Sider>
      <Layout className="site-layout">
        <Header />
        <Content id="scrollableDiv" className={styles.mainContent}>
          {/*<Breadcrumb style={{ margin: '16px 0' }}>*/}
          {/*  <Breadcrumb.Item>User</Breadcrumb.Item>*/}
          {/*  <Breadcrumb.Item>Bill</Breadcrumb.Item>*/}
          {/*</Breadcrumb>*/}
          <div
            className="site-layout-background"
            style={{
              padding: 0,
              minHeight: '100%',
              display: 'flex',
              flexDirection: 'column',
              maxHeight: location.pathname.startsWith('/processes') ? '100%' : undefined,
            }}
          >
            {children}
          </div>
          {/*<div>*/}
          {/*  {children}*/}
          {/*</div>*/}
        </Content>
        {/*<Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer>*/}
        <ForbiddenModal />
      </Layout>
    </Layout>
  );
}
