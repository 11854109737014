import { LoginFirstStepRequest, LoginFirstStepResponse, LoginRequest, LoginResponse } from './types';

import { User } from '../modelTypes/user';
import client from './client';

const LOGIN_URL = 'auth/login';
const ENTER_2FA_PASSWORD_URI = 'auth/enter-2fa-password';
const PROFILE_URL = 'auth/profile';
const NEW_PROCESSES_URL = 'crm/processes/new';
const PROCESSES_URL = 'crm/processes';
const JOBS_URL = 'crm/jobs';

export async function login(payload: LoginFirstStepRequest) {
  return client.post<LoginFirstStepRequest, LoginFirstStepResponse>(LOGIN_URL, payload);
}

export async function enter2FaPassword(payload: LoginRequest) {
  return client.post<LoginRequest, LoginResponse>(ENTER_2FA_PASSWORD_URI, payload);
}

export async function getProfile() {
  return client.get<User>(PROFILE_URL);
}

export async function getNewProcesses() {
  return client.get<any>(NEW_PROCESSES_URL);
}

export async function getProcesses(params?: any) {
  return client.get<any>(PROCESSES_URL, params);
}

export async function getJobs(params?: any) {
  return client.get<any>(JOBS_URL, params);
}
