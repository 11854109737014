import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { JobStatus } from '..';

interface Props {
  status: JobStatus;
}

export default function Status({ status }: Props) {
  return (
    <Tag
      icon={
        {
          0: <ClockCircleOutlined />,
          100: <SyncOutlined spin />,
          200: <CheckCircleOutlined />,
          500: <CloseCircleOutlined />,
        }[status]
      }
      color={{ 0: 'default', 100: 'processing', 200: 'success', 500: 'error' }[status]}
    >
      {{ 0: 'Pending', 100: 'In progress', 200: 'Success', 500: 'Failed' }[status]}
    </Tag>
  );
}
