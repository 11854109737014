import { Process } from '@layouts/Notification/index.ts';
import Index from './Index.tsx';

export { Index };

export enum JobStatus {
  PENDING = 0,
  IN_PROGRESS = 100,
  SUCCESS = 200,
  FAILURE = 500,
}

export enum JobType {
  DOCTOR_PARSE_PUBMED = 100,
  DOCTOR_PARSE_RESEARCHGATE = 101,
  DOCTOR_PARSE_SEMANTICSCHOLAR = 102,
  DOCTOR_PARSE_GOOGLE_REVIEW = 103,
  DOCTOR_FIND_UNIQUE_PUBLICATIONS = 199,
  HUB_EMAIL_OTP = 1000,
  CRM_GALLERY_TRANSLATE_NAME = 10000,
}

export interface Job {
  id: number;
  userId: number;
  type: JobType;
  entityId: number;
  status: JobStatus;
  name: string;
  description: string | null;
  runAt: Date;
  payload: any | null;
  processes: Process[];
  'with-planned'?: true;
}
