import {
  createArticle,
  deleteArticle,
  getAccessKey,
  getArticle,
  getArticles,
  getArticlesForSelect,
  getArticlesForSelect2,
  updateArticle
} from './articleApi';
import {
  createAuthor,
  createAuthorCertificates,
  deleteAuthor,
  deleteAuthorCertificateImage,
  getAuthor,
  getAuthorCertificates,
  getAuthors,
  getAuthorsForSelect,
  updateAuthor,
  updateAuthorCertificate,
  updateAuthorCertificateOrder
} from './authorApi';
import {
  createCertificate,
  deleteCertificate,
  getCertificate,
  getCertificates,
  getCertificatesForSelect,
  updateCertificate
} from '@api/certificateApi';
import {
  createCity,
  deleteCity,
  getCities,
  getCitiesForSelect,
  getCitiesForSelect2,
  getCity,
  updateCity
} from '@api/cityApi';
import {
  createCountry,
  deleteCountry,
  getCountries,
  getCountriesForSelect,
  getCountriesForSelect2,
  getCountry,
  updateCountry
} from '@api/countryApi';
import {
  createDepartment,
  createHospital,
  createHospitalImages,
  deleteDepartment,
  deleteHospital,
  deleteHospitalImage,
  editHospital,
  getDepartment,
  getDepartments,
  getDepartmentsForSelect, getDestinationContentsByPlace, getDestinationsContents,
  getHospital,
  getHospitalAccessKey,
  getHospitals,
  getHospitalsContents,
  getHospitalsContentsByPlace,
  getHospitalsForSelect,
  updateDepartment, updateDestinationsContentByPlace,
  updateHospitalContentByPlace,
  updateHospitalImage,
  updateHospitalImageOrder
} from '@api/hospitalApi';
import {
  createDoctor,
  deleteDoctor,
  doctorGenerateAbout,
  doctorParseData,
  getDoctor,
  getDoctorPublications,
  getDoctors,
  getDoctorsContents,
  getDoctorsContentsBy,
  getDoctorsForSelect,
  updateDoctor,
  updateDoctorContentByPlace,
  updateDoctorsFiltersCount
} from './doctorApi';
import {
  createFrontendLanguages,
  deleteFrontendLanguages,
  getFrontendLanguages,
  updateFrontendLanguages
} from '@api/frontendLanguagesApi';
import {
  createOrder,
  deleteOrders,
  getOrders,
  getPhoneCountriesForSelect,
  getStatusesForSelect,
  updateOrder
} from './orderApi';
import { createRole, deleteRole, getPermissions, getRole, getRoles, getRolesForSelect, updateRole } from '@api/roleApi';
import {
  createSearchSection,
  createSearchSectionExcludedHospitals,
  createSearchSectionRelation,
  deleteExcludedHospital,
  deleteExcludedHospitals,
  deleteSearchSection,
  deleteSearchSectionAllRelation,
  deleteSearchSectionRelation,
  getSearchSection,
  getSearchSectionDepartmentsForSelect,
  getSearchSectionDiseaseForSelect,
  getSearchSectionExcludedHospitals,
  getSearchSectionRelations,
  getSearchSectionRelationsForSelect,
  getSearchSectionRelationsForTreeSelect,
  getSearchSections,
  importSearchSectionExcludedHospitals,
  importSearchSectionRelation,
  updateSearchSection,
  updateSearchSectionTranslation,
} from './searchSectionApi';
import {
  createSearchSynonym,
  deleteSearchSynonym,
  getSearchSynonym,
  getSearchSynonyms,
  getSearchSynonymsForSelect,
  updateSearchSynonym,
} from '@api/searchSynonymApi';
import {
  createSpecialization,
  deleteSpecialization,
  getSpecialization,
  getSpecializations,
  getSpecializationsForSelect,
  getSpecializationsForSelect2,
  updateSpecialization
} from './specializationApi';
import { createTag, deleteTag, getTags, getTagsForSelect, updateTag } from '@api/tagApi';
import { createTemplate, deleteTemplate, getTemplate, getTemplates, updateTemplate } from '@api/templateApi';
import {
  createUser,
  deleteUser,
  getUser,
  getUsers,
  getUsersForSelect,
  requestPermission,
  resetGoogleSecret,
  updateUser
} from './usersApi';
import { getLanguages, updateFrontendDictionary } from '@api/languageApi';
import {
  createMenuItem,
  createMenuSubItem,
  deleteMenuItem,
  deleteSubMenuItemLink,
  getContent,
  getContents,
  getMenuContents,
  getMenuItem,
  getMenuSubItems,
  updateContentRelation,
  updateMenuItem,
  updateMenuSubItem,
  updateMenuSubItemLink
} from './contentApi';
import { getJobs, getNewProcesses, getProfile, login } from './authApi';

import { AxiosError } from 'axios';
import { clearCache } from '@api/cacheApi';
import {
  createProgramDiscount,
  createTreatmentFolder,
  createTreatmentMethod,
  createTreatmentProgram,
  createTreatmentProgramExcludedHospitals,
  createTreatmentProgramRelation,
  createTreatmentServiceCost,
  createTreatmentType,
  deleteProgramDiscount,
  deleteTreatmentFolder,
  deleteTreatmentMethod,
  deleteTreatmentProgram,
  deleteTreatmentProgramAllExcludedHospital,
  deleteTreatmentProgramAllRelation,
  deleteTreatmentProgramExcludedHospital,
  deleteTreatmentProgramRelation,
  deleteTreatmentServiceCost,
  deleteTreatmentType,
  getProgramDiscount,
  getProgramDiscounts,
  getProgramDiscountsForSelect,
  getProgramPrices,
  getTreatmentFolder,
  getTreatmentFolders,
  getTreatmentFoldersForSelect,
  getTreatmentMethod,
  getTreatmentMethods,
  getTreatmentMethodsForSelect,
  getTreatmentProgram,
  getTreatmentProgramExcludedHospitals,
  getTreatmentProgramRelation,
  getTreatmentPrograms,
  getTreatmentProgramsForSelect,
  getTreatmentServiceCost,
  getTreatmentServicesCost,
  getTreatmentServicesCostForSelect,
  getTreatmentType,
  getTreatmentTypes,
  getTreatmentTypesForSelect,
  importTreatmentBlogRelation,
  importTreatmentProgramExcludedHospital,
  importTreatmentProgramRelation,
  importTreatmentProgramSSRelations,
  syncAllTreatmentProgramsPrices,
  syncAllTreatmentProgramsRelation,
  syncTreatmentProgramRelation,
  updateProgramDiscount,
  updateProgramPrice,
  updateTreatmentFolder,
  updateTreatmentMethod,
  updateTreatmentProgram,
  updateTreatmentProgramSSRelations,
  updateTreatmentServiceCost,
  updateTreatmentType
} from '@api/treatmentApi';
import { deleteContentNavigation } from './contentNavigationApi';

export type ApiError = AxiosError;

export default {
  login,
  getProfile,
  getProcesses: getNewProcesses,
  getLanguages,
  updateFrontendDictionary,
  clearCache,

  // Users.
  getUsers,
  getUsersForSelect,
  getUser,
  createUser,
  updateUser,
  deleteUser,
  resetGoogleSecret,
  requestPermission,

  // Roles.
  getRoles,
  getRolesForSelect,
  getRole,
  createRole,
  updateRole,
  deleteRole,
  getPermissions,

  // Frontend languages.
  getFrontendLanguages,
  createFrontendLanguages,
  updateFrontendLanguages,
  deleteFrontendLanguages,

  // Certificate.
  getCertificates,
  getCertificatesForSelect,
  getCertificate,
  createCertificate,
  updateCertificate,
  deleteCertificate,

  // Content.
  getContents,
  getMenuContents,
  getMenuSubItems,
  createMenuSubItem,
  updateMenuSubItem,
  updateMenuSubItemLink,
  deleteSubMenuItemLink,
  deleteContentNavigation,
  getContent,
  updateContentRelation,
  createMenuItem,
  getMenuItem,
  updateMenuItem,
  deleteMenuItem,

  // Tag.
  getTags,
  getTagsForSelect,
  createTag,
  updateTag,
  deleteTag,

  // Country.
  getCountries,
  getCountriesForSelect,
  getCountriesForSelect2,
  getCountry,
  createCountry,
  updateCountry,
  deleteCountry,

  // City.
  getCities,
  getCitiesForSelect,
  getCitiesForSelect2,
  getCity,
  createCity,
  updateCity,
  deleteCity,

  // Doctors.
  getDoctors,
  getDoctorsForSelect,
  getDoctor,
  createDoctor,
  updateDoctor,
  updateDoctorsFiltersCount,
  deleteDoctor,
  doctorParseData,
  doctorGenerateAbout,
  getDoctorsContents,
  getDoctorsContentsBy,
  updateDoctorContentByPlace,
  getDoctorPublications,

  // Specializations.
  getSpecializations,
  getSpecializationsForSelect,
  getSpecialization,
  createSpecialization,
  updateSpecialization,
  deleteSpecialization,
  getSpecializationsForSelect2,

  // Treatment.
  // Treatment Methods.
  getTreatmentMethods,
  getTreatmentMethodsForSelect,
  getTreatmentMethod,
  createTreatmentMethod,
  updateTreatmentMethod,
  deleteTreatmentMethod,

  // Treatment Types of care.
  getTreatmentTypes,
  getTreatmentTypesForSelect,
  getTreatmentType,
  createTreatmentType,
  updateTreatmentType,
  deleteTreatmentType,

  // Treatment Services cost.
  getTreatmentServicesCost,
  getTreatmentServicesCostForSelect,
  getTreatmentServiceCost,
  createTreatmentServiceCost,
  updateTreatmentServiceCost,
  deleteTreatmentServiceCost,

  // Treatment Folders.
  getTreatmentFolders,
  getTreatmentFoldersForSelect,
  getTreatmentFolder,
  createTreatmentFolder,
  updateTreatmentFolder,
  deleteTreatmentFolder,

  // Treatment Programs.
  getTreatmentPrograms,
  getTreatmentProgramsForSelect,
  getTreatmentProgram,
  createTreatmentProgram,
  updateTreatmentProgram,
  updateTreatmentProgramSSRelations,
  importTreatmentProgramSSRelations,
  deleteTreatmentProgram,

  // Treatment Program Blog.
  importTreatmentBlogRelation,

  // Treatment Program Relations.
  getTreatmentProgramRelation,
  createTreatmentProgramRelation,
  importTreatmentProgramRelation,
  syncTreatmentProgramRelation,
  syncAllTreatmentProgramsRelation,
  syncAllTreatmentProgramsPrices,
  deleteTreatmentProgramRelation,
  deleteTreatmentProgramAllRelation,

  // Treatment Program Excluded Hospitals.
  getTreatmentProgramExcludedHospitals,
  createTreatmentProgramExcludedHospitals,
  importTreatmentProgramExcludedHospital,
  deleteTreatmentProgramExcludedHospital,
  deleteTreatmentProgramAllExcludedHospital,

  // Treatment Program Discounts.
  getProgramDiscounts,
  getProgramDiscountsForSelect,
  getProgramDiscount,
  createProgramDiscount,
  updateProgramDiscount,
  deleteProgramDiscount,

  // Treatment Program Price.
  getProgramPrices,
  updateProgramPrice,

  // Hospital.
  getHospitals,
  getHospitalsForSelect,
  getHospital,
  getHospitalAccessKey,
  createHospital,
  editHospital,
  deleteHospital,

  getDepartments,
  getDepartmentsForSelect,
  getDepartment,
  createDepartment,
  updateDepartment,
  deleteDepartment,
  createHospitalImages,
  updateHospitalImage,
  updateHospitalImageOrder,
  deleteHospitalImage,
  getHospitalsContents,
  getHospitalsContentsByPlace,
  updateHospitalContentByPlace,

  getDestinationsContents,
  getDestinationContentsByPlace,
  updateDestinationsContentByPlace,

  // Authors.
  getAuthors,
  getAuthorsForSelect,
  getAuthor,
  createAuthor,
  updateAuthor,
  deleteAuthor,
  getAuthorCertificates,
  createAuthorCertificates,
  updateAuthorCertificate,
  updateAuthorCertificateOrder,
  deleteAuthorCertificateImage,

  // Blog.
  getArticles,
  getArticlesForSelect,
  getArticlesForSelect2,
  getArticle,
  getAccessKey,
  createArticle,
  updateArticle,
  deleteArticle,

  // Templates.
  getTemplates,
  getTemplate,
  createTemplate,
  updateTemplate,
  deleteTemplate,

  // Orders.
  getOrders,
  getPhoneCountriesForSelect,
  getStatusesForSelect,
  createOrder,
  updateOrder,
  deleteOrders,

  // Search Section.
  getSearchSections,
  getSearchSection,
  createSearchSection,
  updateSearchSection,
  updateSearchSectionTranslation,
  deleteSearchSection,
  getSearchSectionRelations,
  getSearchSectionRelationsForSelect,
  getSearchSectionRelationsForTreeSelect,
  createSearchSectionRelation,
  importSearchSectionRelation,
  importSearchSectionExcludedHospitals,
  deleteSearchSectionRelation,
  deleteSearchSectionAllRelation,
  getSearchSectionDepartmentsForSelect,
  getSearchSectionExcludedHospitals,
  createSearchSectionExcludedHospitals,
  deleteExcludedHospital,
  deleteExcludedHospitals,

  // Search Synonym.
  getSearchSynonyms,
  getSearchSynonymsForSelect,
  getSearchSynonym,
  createSearchSynonym,
  updateSearchSynonym,
  deleteSearchSynonym,
};

export {
  login,
  getProfile,
  getNewProcesses as getProcesses,
  getJobs,
  getLanguages,
  updateFrontendDictionary,
  clearCache,

  // Users.
  getUsers,
  getUsersForSelect,
  getUser,
  createUser,
  updateUser,
  deleteUser,
  resetGoogleSecret,
  requestPermission,

  // Roles.
  getRoles,
  getRolesForSelect,
  getRole,
  createRole,
  updateRole,
  deleteRole,
  getPermissions,

  // Frontend languages.
  getFrontendLanguages,
  createFrontendLanguages,
  updateFrontendLanguages,
  deleteFrontendLanguages,

  // Certificate.
  getCertificates,
  getCertificatesForSelect,
  getCertificate,
  createCertificate,
  updateCertificate,
  deleteCertificate,

  // Content.
  getContents,
  getMenuContents,
  getMenuSubItems,
  createMenuSubItem,
  updateMenuSubItem,
  updateMenuSubItemLink,
  deleteSubMenuItemLink,
  deleteContentNavigation,
  getContent,
  updateContentRelation,
  createMenuItem,
  getMenuItem,
  updateMenuItem,
  deleteMenuItem,

  // Tag.
  getTags,
  getTagsForSelect,
  createTag,
  updateTag,
  deleteTag,

  // Country.
  getCountries,
  getCountriesForSelect,
  getCountriesForSelect2,
  getCountry,
  createCountry,
  updateCountry,
  deleteCountry,

  // Cities.
  getCities,
  getCitiesForSelect,
  getCitiesForSelect2,
  getCity,
  createCity,
  updateCity,
  deleteCity,

  // Doctors.
  getDoctors,
  getDoctorsForSelect,
  getDoctor,
  createDoctor,
  updateDoctor,
  updateDoctorsFiltersCount,
  deleteDoctor,
  doctorParseData,
  doctorGenerateAbout,
  getDoctorsContents,
  getDoctorsContentsBy,
  updateDoctorContentByPlace,
  getDoctorPublications,

  // Specializations.
  getSpecializations,
  getSpecializationsForSelect,
  getSpecialization,
  createSpecialization,
  updateSpecialization,
  deleteSpecialization,
  getSpecializationsForSelect2,

  // Treatment.
  // Treatment Methods.
  getTreatmentMethods,
  getTreatmentMethodsForSelect,
  getTreatmentMethod,
  createTreatmentMethod,
  updateTreatmentMethod,
  deleteTreatmentMethod,

  // Treatment Types of care.
  getTreatmentTypes,
  getTreatmentTypesForSelect,
  getTreatmentType,
  createTreatmentType,
  updateTreatmentType,
  deleteTreatmentType,

  // Treatment Services cost.
  getTreatmentServicesCost,
  getTreatmentServicesCostForSelect,
  getTreatmentServiceCost,
  createTreatmentServiceCost,
  updateTreatmentServiceCost,
  deleteTreatmentServiceCost,

  // Treatment Folders.
  getTreatmentFolders,
  getTreatmentFoldersForSelect,
  getTreatmentFolder,
  createTreatmentFolder,
  updateTreatmentFolder,
  deleteTreatmentFolder,

  // Treatment Programs.
  getTreatmentPrograms,
  getTreatmentProgramsForSelect,
  getTreatmentProgram,
  createTreatmentProgram,
  updateTreatmentProgram,
  updateTreatmentProgramSSRelations,
  importTreatmentProgramSSRelations,
  deleteTreatmentProgram,

  // Treatment Program Blog.
  importTreatmentBlogRelation,


  // Treatment Program Relations.
  getTreatmentProgramRelation,
  createTreatmentProgramRelation,
  importTreatmentProgramRelation,
  syncTreatmentProgramRelation,
  syncAllTreatmentProgramsRelation,
  syncAllTreatmentProgramsPrices,
  deleteTreatmentProgramRelation,
  deleteTreatmentProgramAllRelation,

  // Treatment Program Excluded Hospitals.
  getTreatmentProgramExcludedHospitals,
  createTreatmentProgramExcludedHospitals,
  importTreatmentProgramExcludedHospital,
  deleteTreatmentProgramExcludedHospital,
  deleteTreatmentProgramAllExcludedHospital,

  // Treatment Program Discounts.
  getProgramDiscounts,
  getProgramDiscountsForSelect,
  getProgramDiscount,
  createProgramDiscount,
  updateProgramDiscount,
  deleteProgramDiscount,

  // Treatment Program Price.
  getProgramPrices,
  updateProgramPrice,

  // Hospital.
  getHospitals,
  getHospitalsForSelect,
  getHospital,
  getHospitalAccessKey,
  createHospital,
  editHospital,
  deleteHospital,

  getDepartments,
  getDepartmentsForSelect,
  getDepartment,
  createDepartment,
  updateDepartment,
  deleteDepartment,
  createHospitalImages,
  updateHospitalImage,
  updateHospitalImageOrder,
  deleteHospitalImage,
  getHospitalsContents,
  getHospitalsContentsByPlace,
  updateHospitalContentByPlace,

  getDestinationsContents,
  getDestinationContentsByPlace,
  updateDestinationsContentByPlace,

  // Authors.
  getAuthors,
  getAuthorsForSelect,
  getAuthor,
  createAuthor,
  updateAuthor,
  deleteAuthor,
  createAuthorCertificates,
  updateAuthorCertificate,
  updateAuthorCertificateOrder,
  deleteAuthorCertificateImage,

  // Blog.
  getArticles,
  getArticlesForSelect,
  getArticlesForSelect2,
  getArticle,
  getAccessKey,
  createArticle,
  updateArticle,
  deleteArticle,

  // Templates.
  getTemplates,
  getTemplate,
  createTemplate,
  updateTemplate,
  deleteTemplate,

  // Orders.
  getOrders,
  getPhoneCountriesForSelect,
  getStatusesForSelect,
  createOrder,
  updateOrder,
  deleteOrders,

  // Search Section.
  getSearchSections,
  getSearchSection,
  createSearchSection,
  updateSearchSection,
  updateSearchSectionTranslation,
  deleteSearchSection,
  getSearchSectionRelations,
  getSearchSectionRelationsForSelect,
  getSearchSectionRelationsForTreeSelect,
  createSearchSectionRelation,
  importSearchSectionRelation,
  importSearchSectionExcludedHospitals,
  deleteSearchSectionRelation,
  deleteSearchSectionAllRelation,
  getSearchSectionDepartmentsForSelect,
  getSearchSectionExcludedHospitals,
  createSearchSectionExcludedHospitals,
  deleteExcludedHospital,
  deleteExcludedHospitals,
  getSearchSectionDiseaseForSelect,


  // Search Synonym.
  getSearchSynonyms,
  getSearchSynonymsForSelect,
  getSearchSynonym,
  createSearchSynonym,
  updateSearchSynonym,
  deleteSearchSynonym,
};
