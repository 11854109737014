import { CheckCircleOutlined, CloseCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { ProcessStatus } from '@layouts/Notification';
import { Tag } from 'antd';

interface Props {
  status: ProcessStatus;
}

export default function Status({ status }: Props) {
  return (
    <Tag
      icon={
        {
          1: <SyncOutlined spin />,
          2: <CloseCircleOutlined />,
          3: <CheckCircleOutlined />,
          100: <CheckCircleOutlined />,
        }[status]
      }
      color={{ 1: 'processing', 2: 'error', 3: 'success', 100: 'success' }[status]}
    >
      {{ 1: 'In progress', 2: 'Failure', 3: 'Success', 100: 'Finished' }[status]}
    </Tag>
  );
}
