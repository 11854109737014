import { PageHeader } from '@ant-design/pro-layout';
import { CreateGalleriesVideoRequest } from '@api/types';
import AddVideoGalleries from '@components/AddVideoGalleries';
import GalleryGrid from '@components/Gallery/GalleryGrid';
import UploadImageGalleries from '@components/UploadImageGalleries';
import UploadVideoGalleries from '@components/UploadVideoGalleries';
import { useGalleriesDataStore } from '@zustandStorage/galleries/galleries';
import { useGridStore } from '@zustandStorage/grid/grid';
import { Dropdown, message } from 'antd';
import { AxiosRequestConfig } from 'axios';
import QueryString from 'query-string';
import { useHistory, useParams } from 'react-router-dom';
import UploadMultipleImages from '@components/Gallery/components/UploadMultipleImages';
import { UploadOutlined } from '@ant-design/icons';

interface GalleryStepProps {
  entityGallery: string;
  backPath: string;
  selectStep: JSX.Element;
  hasMainImages?: boolean;
}

export default function GalleryStep({ entityGallery, backPath, selectStep, hasMainImages }: GalleryStepProps) {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const { createGalleriesImages, fetchGalleriesItem, addYoutubeVideo, uploadVideo } = useGalleriesDataStore();

  const handleSaveImage = async (formData: FormData) => {
    formData.append(entityGallery, id);
    return createGalleriesImages(formData);
  };

  const handleSaveEmbedVideo = async (formData: CreateGalleriesVideoRequest) => {
    // formData.append('doctorId', id)
    const data = { ...formData, [entityGallery]: +id };
    return addYoutubeVideo(data);
  };

  const handleSaveVideo = async (formData: FormData, onUploadProgress?: AxiosRequestConfig<any>['onUploadProgress']) => {
    formData.append(entityGallery, id);
    return uploadVideo(formData, onUploadProgress);
  };

  const updateGrid = async () => {
    fetchGalleriesItem({ [entityGallery]: +id })
      //   .then((data: any) => {
      //   // message.success('The gallery has been successfully update');
      // })
      .catch((e: any) => {
        message.error('The gallery not update\n' + 'An error occurred, please try again now');
      });
  };

  const onMenuClick = (e: any) => {
    document.getElementById(e.key)?.click();
  };

  return (
    <>
      <PageHeader
        ghost={true}
        onBack={() => {
          const params = useGridStore.getState().gridOptions.gridOptions || {};
          history.push(`${backPath}?${QueryString.stringify(params)}`);
        }}
        title="Gallery"
        extra={[
          // <SelectStep key="selectStep" />,
          selectStep,
          <Dropdown.Button
            onClick={() => {
              document.getElementById('galleryUploadImage')?.click();
            }}
            menu={{
              items: [
                {
                  key: 'galleryUploadMultipleImage',
                  label: 'Multiple Images',
                },
                {
                  key: 'galleryAddYoutubeVideo',
                  label: 'Youtube Video',
                },
                {
                  key: 'galleryUploadVideo',
                  label: 'Video',
                }
              ],
              onClick: onMenuClick
            }}
          >
            <UploadOutlined /> Upload Image
          </Dropdown.Button>,
        ]}
      />
      <UploadImageGalleries key="uploadImage" handleSaveForm={handleSaveImage} updateGrid={updateGrid} />
      <UploadMultipleImages key="multipleUploadImage" entityGallery={entityGallery} id={id} updateGrid={updateGrid} />
      <AddVideoGalleries key="addVideo" handleSaveForm={handleSaveEmbedVideo} updateGrid={updateGrid} />
      <UploadVideoGalleries key="uploadVideo" handleSaveForm={handleSaveVideo} updateGrid={updateGrid} />
      <GalleryGrid entityGallery={entityGallery} hasMainImages={hasMainImages} />
    </>
  );
}
