import {
  CreateGalleriesResponse,
  CreateGalleriesVideoRequest,
  ListResponse,
  Params,
  UpdateGalleriesRequest,
  UpdateGalleriesResponse,
  UpdateHospitalImageOrderRequest,
  UpdateHospitalImageOrderResponse,
  UpdateImageOrderRequest,
  UpdateImageOrderResponse,
} from '@api/types';
import client from './client';
import { GalleriesItem } from '@modelTypes/galleries';
import { AxiosRequestConfig } from 'axios';

const GET_GALLERIES_DATA = '/galleries'
const POST_GALLERIES_IMAGES = '/galleries/images'
const POST_GALLERIES_PURE_IMAGES = '/galleries/pure-images'
const POST_UPLOAD_VIDEO_URI = '/galleries/videos';
const POST_ADD_YT_VIDEO_URI = '/galleries/youtube-videos';
const PATCH_GALLERIES_ITEM = (id: number) => `/galleries/${id}`
const PATCH_IMAGES_ORDER_URI = (id: number) => `/galleries/${id}/order`;
const DELETE_GALLERIES_ITEM = (id: number) => `/galleries/${id}`

export async function getGalleriesItem(params: Params) {
  return client.get<ListResponse<GalleriesItem>>( GET_GALLERIES_DATA, params);
}

export async function createGalleriesImages(payload: FormData) {
  return client.post<FormData, CreateGalleriesResponse>(POST_GALLERIES_IMAGES, payload);
}

export async function createGalleriesPureImages(payload: FormData, onUploadProgress: any) {
  return client.post<FormData, CreateGalleriesResponse>(POST_GALLERIES_PURE_IMAGES, payload, {}, onUploadProgress);
}

export async function uploadVideo(body: FormData, onUploadProgress?: AxiosRequestConfig<any>['onUploadProgress']) {
  return client.post<FormData, CreateGalleriesResponse>(POST_UPLOAD_VIDEO_URI, body, undefined, onUploadProgress);
}

export async function addYoutubeVideo(body: any) {
  return client.post<CreateGalleriesVideoRequest, CreateGalleriesResponse>(POST_ADD_YT_VIDEO_URI, body);
}

export async function updateGalleriesItem(id: number, payload: UpdateGalleriesRequest) {
  return client.patch<UpdateGalleriesRequest, UpdateGalleriesResponse>(PATCH_GALLERIES_ITEM(id), payload);
}

export async function updateImageOrder(id: number, payload: UpdateImageOrderRequest) {
  return client.patch<UpdateImageOrderRequest, UpdateImageOrderResponse>(PATCH_IMAGES_ORDER_URI(id), payload);
}

export async function deleteGalleriesItem(id: number, payload?: AxiosRequestConfig) {
  return client.del(DELETE_GALLERIES_ITEM(id), payload);
}
