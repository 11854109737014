import { Select } from 'antd';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { Filters as FiltersType, getDefaultFilters } from '.';

interface Props {
  filters: FiltersType;
  onChange: Dispatch<SetStateAction<FiltersType>>;
}

export default function Filters({ filters, onChange }: Props) {
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const newFilters: typeof filters = {
      ...getDefaultFilters(),
      ...Object.fromEntries(searchParams.entries()),
    };
    onChange(newFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams();

    for (const entry of Object.entries(filters)) {
      if (!entry[1]) {
        continue;
      }

      searchParams.set(...entry);
    }

    const queryString = searchParams.toString();

    window.history.replaceState(null, '', `${window.location.pathname}${queryString ? `?${queryString}` : ''}`);
  }, [filters]);

  return (
    <div style={{ display: 'flex', gap: 30, marginTop: 25 }}>
      <Select
        value={filters.type}
        onChange={(value) => onChange((prev) => ({ ...prev, type: value }))}
        options={[
          { value: '', label: 'Select type' },
          { value: '100', label: 'DOCTOR_PARSE_PUBMED' },
          { value: '101', label: 'DOCTOR_PARSE_RESEARCHGATE' },
          { value: '102', label: 'DOCTOR_PARSE_SEMANTICSCHOLAR' },
          { value: '103', label: 'DOCTOR_PARSE_GOOGLE_REVIEW' },
          { value: '199', label: 'DOCTOR_FIND_UNIQUE_PUBLICATIONS' },
          { value: '1000', label: 'HUB_EMAIL_OTP' },
          { value: '10000', label: 'CRM_GALLERY_TRANSLATE_NAME' },
        ]}
        style={{ width: 300 }}
      />
      <Select
        value={filters.status}
        onChange={(value) => onChange((prev) => ({ ...prev, status: value }))}
        options={[
          { value: '', label: 'Select status' },
          { value: '0', label: 'PENDING' },
          { value: '100', label: 'IN_PROGRESS' },
          { value: '200', label: 'SUCCESS' },
          { value: '500', label: 'FAILURE' },
        ]}
        style={{ width: 150 }}
      />
      <Select
        value={filters.planned}
        onChange={(value) => onChange((prev) => ({ ...prev, planned: value }))}
        options={[
          { value: '', label: 'Without planned' },
          { value: 'with', label: 'With planned' },
        ]}
        style={{ width: 150 }}
      />
    </div>
  );
}
