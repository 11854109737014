import { Link } from 'react-router-dom';
import { JobType } from '..';
import getIdCell from '@components/AntGrid/cells/IdCell';

interface Props {
  entityId: number;
  type: JobType;
}

export default function EntityUrl({ entityId, type }: Props) {
  const entityType = {
    100: 'doctors',
    101: 'doctors',
    102: 'doctors',
    103: 'doctors',
    199: 'doctors',
    1000: null,
    10000: null,
  }[type];

  if (!entityType) {
    return getIdCell()(entityId);
  }

  return (
    <Link to={`/${entityType}/edit/${entityId}`} target="_blank">
      {entityId}
    </Link>
  );
}
