import { getJobs } from '@api/authApi';
import getIdCell from '@components/AntGrid/cells/IdCell';
import ProcessEntityUrl from '@containers/Processes/EntityUrl';
import ProcessStatus from '@containers/Processes/Status';
import ProcessType from '@containers/Processes/Type';
import { ProcessStatus as EProcessStatus, ProcessType as EProcessType } from '@layouts/Notification';
import { message, Table } from 'antd';
import moment from 'moment';
import { SetStateAction, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { JobStatus as EJobStatus, Job } from '.';
import JobEntityUrl from './EntityUrl';
import Filters, { Filters as FiltersType, getDefaultFilters } from './Filters';
import Payload from './Payload';
import JobStatus from './Status';

export default function Index() {
  const perPage = 100;
  const [page, setPage] = useState(1);
  const [jobs, setJobs] = useState<Job[]>();
  const [totalJobs, setTotalJobs] = useState(0);
  const [isFetching, setFetching] = useState(false);
  const [filters, setFilters] = useState<FiltersType>(getDefaultFilters());

  useEffect(() => {
    (async () => {
      setFetching(true);

      try {
        const params = {
          perPage,
          page,
          type: filters.type ? +filters.type : null,
          status: filters.status ? +filters.status : null,
          'with-planned': filters.planned ? true : null,
        };
        const data = await getJobs(Object.fromEntries(Object.entries(params).filter(Boolean)));
        setJobs((data.data as Job[]).map((i) => ({ ...i, key: i.id })));
        setTotalJobs(data.meta.total);
      } catch (err: any) {
        message.error(`Failed to fetch jobs: ${err.message}`);
      } finally {
        setFetching(false);
      }
    })();
  }, [filters.planned, filters.status, filters.type, page]);

  const handleFiltersChange = (setStateAction: SetStateAction<FiltersType>) => {
    setFilters(setStateAction);
    setPage(1);
  };

  return (
    <>
      <Filters filters={filters} onChange={handleFiltersChange} />
      <Table
        loading={isFetching}
        style={{ marginTop: 20, flex: 1 }}
        columns={[
          { title: 'ID', dataIndex: 'id', render: (value: number) => getIdCell()(value) },
          {
            title: 'User ID',
            dataIndex: 'userId',
            render: (value: number) => (
              <Link to={`/users/${value}/edit`} target="_blank">
                {value}
              </Link>
            ),
          },
          {
            title: 'Entity ID',
            dataIndex: 'entityId',
            render: (value: number, record) => <JobEntityUrl entityId={value} type={record.type} />,
          },
          {
            title: 'Status',
            dataIndex: 'status',
            render: (value: EJobStatus) => <JobStatus status={value} />,
          },
          { title: 'Name', dataIndex: 'name' },
          { title: 'Description', dataIndex: 'description' },
          {
            title: 'Payload',
            dataIndex: 'payload',
            render: (value: any | null) => (value ? <Payload json={value} /> : null),
          },
          {
            title: 'Run at',
            dataIndex: 'runAt',
            render: (value) => moment(value).format('HH:mm DD.MM.YYYY'),
          },
        ]}
        dataSource={jobs}
        pagination={{
          pageSize: perPage,
          total: totalJobs,
          showSizeChanger: false,
          style: {
            position: 'sticky',
            bottom: 0,
            background: '#f5f5f5',
            paddingBlock: 10,
            paddingBottom: 26,
            marginBottom: 0,
          },
        }}
        expandable={{
          expandedRowRender: (record) => (
            <>
              <Table
                columns={[
                  { title: 'ID', dataIndex: 'id', render: (value: number) => getIdCell()(value) },
                  {
                    title: 'Type',
                    dataIndex: 'type',
                    render: (value: EProcessType) => <ProcessType type={value} />,
                  },
                  {
                    title: 'Entity ID',
                    dataIndex: 'entityId',
                    render: (value: number, record) => <ProcessEntityUrl entityId={value} type={record.type} />,
                  },
                  {
                    title: 'Status',
                    dataIndex: 'status',
                    render: (value: EProcessStatus) => <ProcessStatus status={value} />,
                  },
                  { title: 'Name', dataIndex: 'name' },
                  {
                    title: 'Created at',
                    dataIndex: 'createdAt',
                    render: (value) => moment(value).format('HH:mm DD.MM.YYYY'),
                  },
                  {
                    title: 'Finished at',
                    dataIndex: 'finishedAt',
                    render: (value) => moment(value).format('HH:mm DD.MM.YYYY'),
                  },
                ]}
                dataSource={record.processes}
                pagination={false}
              />
            </>
          ),
          rowExpandable: (record) => !!record.processes.length,
        }}
        onChange={(cfg) => setPage(cfg.current ?? 1)}
      />
    </>
  );
}
