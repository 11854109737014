import * as Authors from '@containers/Blog/Authors';
import * as Blog from '@containers/Blog';
import * as Contents from '@containers/Contents';
import * as Templates from '@containers/Templates';
import * as Certificates from '@containers/Certificates';
// import * as Cities from '@containers/Destinations/Cities';
// import * as Countries from '@containers/Destinations/Countries';
import * as Destinations from '@containers/Destinations';
import * as Departments from '@containers/Hospitals/Departments';
import * as Doctors from '@containers/Doctors';
// import * as Specializations from '@containers/Specializations';
// import * as ExcludedWords from '@containers/ExcludedWords';
import * as Hospitals from '@containers/Hospitals';
import * as Orders from '@containers/Orders';
import * as Offers from '@containers/Offers';
import * as Tasks from '@containers/Tasks';
import * as Roles from '@containers/Roles';
// import * as SearchEngine from '@containers/SearchEngine';
// import * as Treatments from '@containers/Treatments';
import StaticTagCloud from '@containers/SearchEngine/StaticTagCloud';
import Playground from '@containers/SearchEngine/Playground';
// import * as Synonyms from '@containers/SearchEngine/Synonyms';
import * as Users from '@containers/Users';
import * as Vendors from '@containers/Vendors';
import * as Settings from '@containers/Settings/Settings';
import * as Processes from '@containers/Processes';
import * as Jobs from '@containers/Jobs';

import { AuthLayout } from '@layouts';
import AppLayout from '@layouts/AntLayout/AppLayout';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { getLanguages, getProfile } from '@storage/app/thunkActions';

import Auth from '@containers/Auth';
import BaseAuth from '@base/Auth';
import Index from '@containers/Settings/FrontendLanguages';
import Main from '@containers/Main';
import Tags from '@containers/Tags';
import { updateData } from '@storage/app/actions';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useSelector } from '@hooks/useSelector';

import './App.css';
import LanguagesGrid from '@containers/Settings/Languages';
import LogsGrid from '@containers/Settings/Logs';

export default function App(): JSX.Element {
  const dispatch = useDispatch();
  const hasToken = useSelector<boolean>('app.hasToken');
  // const languages = useSelector<AppState['languages']>('app.languages');

  // const userEmail = useSelector<string>('user.email');

  useEffect(() => {
    dispatch(updateData(BaseAuth.getUserFromLS()));

    if (hasToken) {
      dispatch(getProfile());
      dispatch(getLanguages());
    }
  }, [hasToken]);

  return (
    <BrowserRouter>
      {!hasToken && (
        <AuthLayout>
          <Switch>
            <Route path="/login/enter-ga-code" exact component={Auth.EnterGACode} />
            <Route path="/login/show-ga-qr-code" exact component={Auth.ShowGAQRCode} />
            <Route path="/login" exact component={Auth.Login} />
            <Redirect to="/login" />
          </Switch>
        </AuthLayout>
      )}
      {hasToken && (
        <AppLayout>
          {/*<Loader active={!languages.length}>*/}
            <Switch>
              <Route path="/authors" exact component={Authors.Index} />
              <Route path="/authors/create" exact component={Authors.Create} />
              <Route path="/authors/edit/:id/:step?" exact component={Authors.Create} />

              <Route path="/orders/:status" exact component={Orders.Index} />
              <Route path="/orders/:status/:id" exact component={Orders.OrderDetails} />
              {/*<Route path="/orders/:status/:id/edit" exact component={Orders.OrderDetails} />*/}
              <Route path="/tasks/:status" exact component={Tasks.Index} />

              <Route path="/contents/menu/:page/:id/edit" exact component={Contents.Menu.Create} />
              <Route path="/contents/menu/:page/create" exact component={Contents.Menu.Create} />
              <Route path="/contents/menu/:page/items/:id" exact component={Contents.Menu.Items.Index} />
              <Route path="/contents/menu/:page" exact component={Contents.Menu.Index} />
              <Route path="/contents/:page" exact component={Contents.Index} />
              <Route path="/contents/:page/:id/edit" exact component={Contents.Edit} />

              <Route path="/blog/:type" exact component={Blog.Index} />
              <Route path="/blog/:type/create" exact component={Blog.Create} />
              <Route path="/blog/:type/:id/edit" exact component={Blog.Create} />

              <Route path="/templates/:type" exact component={Templates.Index} />
              <Route path="/templates/:type/create" exact component={Templates.Create} />
              <Route path="/templates/:type/:id/edit" exact component={Templates.Create} />

              <Route path="/frontend-languages/:type" exact component={Index} />
              <Route path="/settings" exact component={Settings.Index} />
              <Route path="/settings/create" exact component={Settings.Create} />
              <Route path="/settings/:id/edit" exact component={Settings.Create} />
              <Route path="/languages" exact component={LanguagesGrid} />
              <Route path="/logs" exact component={LogsGrid} />

              {/*<Route path="/tags/create" exact component={Hospitals.Create} />*/}
              <Route path="/tags" exact component={Tags} />

              {/* <Route path="/doctors/contents" exact component={Doctors.Content.Index} />
              <Route path="/doctors/contents/edit" exact component={Doctors.Content.Create} /> */}
              <Route path="/doctors" exact component={Doctors.Index} />
              <Route path="/doctors/create" exact component={Doctors.Create} />
              {/*<Route path="/doctors/create/:id/:step?" exact component={Doctors.Create} />*/}
              <Route path="/doctors/edit/:id/:step?" exact component={Doctors.Create} />
              {/* <Route path="/refactor-doctors" exact component={Doctors.Refactor} /> */}

              {/* <Route path="/specializations" exact component={Specializations.Index} />
              <Route path="/specializations/create" exact component={Specializations.Create} />
              <Route path="/specializations/:id/edit" exact component={Specializations.Create} /> */}

              {/* <Route path="/excluded-words" exact component={ExcludedWords.Index} />
              <Route path="/excluded-words/create" exact component={ExcludedWords.Create} /> */}
              {/*<Route path="/excluded-words/:id/edit" exact component={Specializations.Create} />*/}

              <Route path="/countries" exact component={Destinations.Countries.Index} />
              <Route path="/countries/create" exact component={Destinations.Countries.Create} />
              <Route path="/countries/:id/edit" exact component={Destinations.Countries.Create} />

              <Route path="/cities" exact component={Destinations.Cities.Index} />
              <Route path="/cities/create" exact component={Destinations.Cities.Create} />
              <Route path="/cities/:id/edit" exact component={Destinations.Cities.Create} />

              {/* <Route path="/destinations/content" exact component={Destinations.Content.Index} />
              <Route path="/destinations/content/edit" exact component={Destinations.Content.Create} /> */}


              <Route path="/certificates" exact component={Certificates.Index} />
              {/*<Route path="/certificates/create" exact component={Certificates.Create} />*/}
              {/*<Route path="/certificates/:id/edit" exact component={Certificates.Create} />*/}

              <Route path="/hospitals/:hospitalId/departments/create" exact component={Departments.Create} />
              <Route path="/hospitals/:hospitalId/departments/:id/edit" exact component={Departments.Create} />
              <Route path="/hospitals/:hospitalId/departments" exact component={Departments.Index} />

              {/* <Route path="/hospitals/contents" exact component={Hospitals.Content.Index} />
              <Route path="/hospitals/contents/edit" exact component={Hospitals.Content.Create} /> */}
              <Route path="/hospitals/create" exact component={Hospitals.Create} />
              <Route path="/hospitals/:id/edit/:step?" exact component={Hospitals.Create} />
              <Route path="/hospitals" exact component={Hospitals.Index} />

              {/* <Route path="/treatments/programs/discounts" exact component={Treatments.Discounts.Index} />
              <Route path="/treatments/programs/discounts/create" exact component={Treatments.Discounts.Create} />
              <Route path="/treatments/programs/discounts/:id/edit" exact component={Treatments.Discounts.Create} />

              <Route path="/treatments/programs/folders" exact component={Treatments.ProgramFolders.Index} />
              <Route path="/treatments/programs/folders/create" exact component={Treatments.ProgramFolders.Create} />
              <Route path="/treatments/programs/folders/:id/edit" exact component={Treatments.ProgramFolders.Create} />

              <Route path="/treatments/programs" exact component={Treatments.Programs.Index} />
              <Route path="/treatments/programs/create" exact component={Treatments.Programs.Create} />
              <Route path="/treatments/programs/edit/:id/:step?" exact component={Treatments.Programs.Create} />

              <Route path="/treatments/methods" exact component={Treatments.Methods.Index} />
              <Route path="/treatments/methods/create" exact component={Treatments.Methods.Create} />
              <Route path="/treatments/methods/:id/edit" exact component={Treatments.Methods.Create} />

              <Route path="/treatments/types-of-care" exact component={Treatments.TypesOfCare.Index} />
              <Route path="/treatments/types-of-care/create" exact component={Treatments.TypesOfCare.Create} />
              <Route path="/treatments/types-of-care/:id/edit" exact component={Treatments.TypesOfCare.Create} />

              <Route path="/treatments/services-cost" exact component={Treatments.ServicesCost.Index} />
              <Route path="/treatments/services-cost/:id/edit" exact component={Treatments.ServicesCost.Create} />

              <Route path="/treatments/methods" exact component={Treatments.Methods.Index} />
              <Route path="/treatments/methods/create" exact component={Treatments.Methods.Create} />
              <Route path="/treatments/methods/:id/edit" exact component={Treatments.Methods.Create} /> */}

              <Route path="/offers" exact component={Offers.Offers.Index} />
              <Route path="/offers/create" exact component={Offers.Offers.Create} />
              <Route path="/offers/:id/edit/:step?" exact component={Offers.Offers.Create} />

              <Route path="/offers/methods" exact component={Offers.Methods.Index} />
              <Route path="/offers/methods/create" exact component={Offers.Methods.Create} />
              <Route path="/offers/methods/:id/edit" exact component={Offers.Methods.Create} />

              <Route path="/offers/types-of-care" exact component={Offers.TypesOfCare.Index} />
              <Route path="/offers/types-of-care/create" exact component={Offers.TypesOfCare.Create} />
              <Route path="/offers/types-of-care/:id/edit" exact component={Offers.TypesOfCare.Create} />

              <Route path="/search-engine/static-tag-cloud" exact component={StaticTagCloud} />
              <Route path="/search-engine/playground" exact component={Playground} />

              {/* <Route path="/search-engine/synonyms" exact component={Synonyms.Index} />
              <Route path="/search-engine/synonyms/create" exact component={Synonyms.Create} />
              <Route path="/search-engine/synonyms/:id/edit" exact component={Synonyms.Create} /> */}

              {/* <Route path="/search-engine/:type" exact component={SearchEngine.Index} />
              <Route path="/search-engine/:type/create" exact component={SearchEngine.CreateNew} />
              <Route path="/search-engine/:type/edit/:id/:step/:contentParentId/sub" exact component={SearchEngine.CreateNew} />
              <Route path="/search-engine/:type/edit/:id/:step?" exact component={SearchEngine.CreateNew} />

              <Route path="/search-engine/:type/:parentId/sections" exact component={SearchEngine.Index} />
              <Route path="/search-engine/:type/:parentId/sections/create" exact component={SearchEngine.CreateNew} />
              <Route path="/search-engine/:type/:parentId/sections/edit/:id/:step?" exact component={SearchEngine.CreateNew} /> */}

              {/*<Route path="/search-engine/:type" exact component={SearchEngine.Index} />*/}
              {/*<Route path="/search-engine/:type/create" exact component={SearchEngine.Create} />*/}
              {/*<Route path="/search-engine/:type/:id/edit" exact component={SearchEngine.Create} />*/}

              {/*<Route path="/search-engine/:type/:parentId/sections" exact component={SearchEngine.Index} />*/}
              {/*<Route path="/search-engine/:type/:parentId/sections/create" exact component={SearchEngine.Create} />*/}
              {/*<Route path="/search-engine/:type/:parentId/sections/:id/edit" exact component={SearchEngine.Create} />*/}

              <Route path="/users" exact component={Users.Index} />
              <Route path="/users/create" exact component={Users.Create} />
              <Route path="/users/:id/edit" exact component={Users.Create} />

              <Route path="/vendors/:seller" exact component={Vendors.Index} />
              <Route path="/vendors/:seller/create" exact component={Vendors.Create} />
              <Route path="/vendors/:seller/:id/edit" exact component={Vendors.Create} />

              <Route path="/roles" exact component={Roles.Index} />
              <Route path="/roles/create" exact component={Roles.Create} />
              <Route path="/roles/:id/edit/" exact component={Roles.Create} />

              <Route path="/processes" exact component={Processes.Index} />

              <Route path="/jobs" exact component={Jobs.Index} />

              <Route path="/" exact component={Main} />
              <Redirect to="/" />
            </Switch>
          {/*</Loader>*/}
        </AppLayout>
      )}
    </BrowserRouter>
  );
}