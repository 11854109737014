import { getProcesses } from '@api/authApi';
import getIdCell from '@components/AntGrid/cells/IdCell';
import { Process, ProcessStatus, ProcessType } from '@layouts/Notification';
import { message, Table } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import EntityUrl from './EntityUrl';
import Status from './Status';
import Type from './Type';

export default function Index() {
  const perPage = 100;
  const [page, setPage] = useState(1);
  const [processes, setProcesses] = useState<Process[]>();
  const [totalProcesses, setTotalProcesses] = useState(0);
  const [isFetching, setFetching] = useState(false);

  useEffect(() => {
    (async () => {
      setFetching(true);

      try {
        const data = await getProcesses({ perPage, page });
        setProcesses((data.data as Process[]).map((i) => ({ ...i, key: i.id })));
        setTotalProcesses(data.meta.total);
      } catch (err: any) {
        message.error(`Failed to fetch processes: ${err.message}`);
      } finally {
        setFetching(false);
      }
    })();
  }, [page]);

  return (
    <Table
      loading={isFetching}
      style={{ marginTop: 20, flex: 1 }}
      columns={[
        { title: 'ID', dataIndex: 'id', render: (value: number) => getIdCell()(value) },
        {
          title: 'Type',
          dataIndex: 'type',
          render: (value: ProcessType) => <Type type={value} />,
        },
        {
          title: 'Entity ID',
          dataIndex: 'entityId',
          render: (value: number, record) => <EntityUrl entityId={value} type={record.type} />,
        },
        {
          title: 'Status',
          dataIndex: 'status',
          render: (value: ProcessStatus) => <Status status={value} />,
        },
        { title: 'Name', dataIndex: 'name' },
        { title: 'Created at', dataIndex: 'createdAt', render: (value) => moment(value).format('HH:mm DD.MM.YYYY') },
        {
          title: 'Finished at',
          dataIndex: 'finishedAt',
          render: (value) => moment(value).format('HH:mm DD.MM.YYYY'),
        },
      ]}
      dataSource={processes}
      pagination={{
        pageSize: perPage,
        total: totalProcesses,
        showSizeChanger: false,
        style: { position: 'sticky', bottom: 0, background: 'white', paddingBlock: 10 },
      }}
      onChange={(cfg) => setPage(cfg.current ?? 1)}
      scroll={{ y: 'max-content' }}
    />
  );
}
