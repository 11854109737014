import { UploadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useState } from 'react';
import UploadVideoModal from './UploadVideoModal';

interface Props {
  handleSaveForm: (formData: FormData) => any;
  updateGrid: () => void;
}

export default function UploadVideoButton({ handleSaveForm, updateGrid }: Props) {
  const [isOpenModal, setIsOpenModal] = useState(false)

  const handleCloseModal = async () => {
    setIsOpenModal(false)
  }

  return (
    <>
      <Button
        id="galleryUploadVideo"
        icon={<UploadOutlined />}
        onClick={() => setIsOpenModal(true)}
        style={{ display: 'none' }}
      >
        Upload Video
      </Button>
      <UploadVideoModal
        updateGrid={updateGrid}
        closeModal={handleCloseModal}
        isOpen={isOpenModal}
        handleSaveForm={handleSaveForm}
      />
    </>
  )
}