import { Button, Modal } from 'antd';
import { useState } from 'react';

interface Props {
  json: any;
}

export default function Payload({ json }: Props) {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setOpen(true)}>View</Button>
      <Modal open={isOpen} onCancel={() => setOpen(false)} footer={null} width={'90vw'}>
        <pre>{JSON.stringify(json, null, 2)}</pre>
      </Modal>
    </>
  );
}
