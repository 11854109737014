import { ProcessType } from '@layouts/Notification';
import { Link } from 'react-router-dom';

interface Props {
  entityId: number;
  type: ProcessType;
}

export default function EntityUrl({ entityId, type }: Props) {
  return (
    <Link
      to={`/${
        { 1: 'doctors', 2: 'doctors', 3: 'doctors', 4: 'doctors', 99: 'doctors', 100: null }[type]
      }/edit/${entityId}`}
      target="_blank"
    >
      {entityId}
    </Link>
  );
}
