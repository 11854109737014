import { Button, Upload, message, Modal, Progress, Spin } from 'antd';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { createGalleriesPureImages } from '@api/galleriesApi';

interface Props {
  entityGallery: string;
  id: string;
  updateGrid: () => void;
}

export default function UploadMultipleImages({ updateGrid, entityGallery, id }: Props) {
  const [fileList, setFileList] = useState<any[]>([]);
  const uploadedFiles = fileList.length
    ? fileList.filter((file) => file.status === 'done').length
    : 0;

  const handleCustomRequest = async (options: any) => {
    const { file, onProgress, onSuccess, onError } = options;

    const formData = new FormData();
    formData.append(entityGallery, id);
    formData.append('image', file);

    try {
      const response = await createGalleriesPureImages(
        formData,
        ({ total, loaded }: any) => {
          console.log('test --- ', total, loaded);
          onProgress({ percent: Number(Math.round((loaded / total) * 100).toFixed(2)) }, file);
        }
      );
      // message.success(`${file.name} uploaded successfully.`);
      onSuccess({ percent: 100, path: response?.path }, file);
    } catch (error) {
      console.error('Upload error:', error);
      message.error(`Failed to upload ${file.name}`);
      onError(error);
    }
  };

  useEffect(() => {
    const notUploadedFiles = fileList.filter((file) => file.status !== 'done');

    if (fileList.length && notUploadedFiles.length === 0) {
      updateGrid();
    }
  }, [fileList]);

  return (
    <div style={{ width: '100%' }}>
      <Upload
        multiple
        name="image"
        accept="image/*"
        listType="picture"
        fileList={fileList}
        onChange={({ fileList }) => setFileList(fileList)}
        customRequest={handleCustomRequest}
        // onChange={({ fileList: newFileList }) => setFileList(newFileList)}
        showUploadList={false}
      >
        <div id="galleryUploadMultipleImage" style={{ display: 'none' }}>Upload Images</div>
      </Upload>
      <Modal
        closable={false}
        cancelButtonProps={{ style: { display: 'none' } }}
        title={<>Uploaded {uploadedFiles}/{fileList.length}</>}
        open={fileList.length > 0}
        onOk={() => {
          setFileList([]);
        }}
      >
        <div style={{ maxHeight: 300, overflowY: 'auto' }}>
          {fileList.map((file) => (
            <FileItem key={file.uid} file={file} />
          ))}
        </div>
      </Modal>
    </div>
  )
}

function FileItem({ file }: any) {
  const isUploaded = file?.response?.path?.length;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: 10,
        borderBottom: '1px solid #f0f0f0',
        paddingBottom: 5,
        gap: 16,
      }}
    >
      <div style={{ width: '50px', height: '50px' }}>
        {isUploaded && (
          <img
            src={file.response.path}
            alt={file.name}
            style={{
              width: 50,
              height: 50,
              objectFit: 'cover',
              borderRadius: 4,
              marginRight: 10,
            }}
          />
        )}
        {!isUploaded && (
          <Spin indicator={<LoadingOutlined spin />} />
        )}
      </div>
      <div style={{ flex: 1 }}>
        <div style={{ fontSize: 14, marginBottom: 5 }}>{file.name}</div>
        {file.status === 'uploading' && (
          <Progress
            percent={Math.round(file.percent || 0)}
            size="small"
            showInfo={false}
          />
        )}
      </div>
    </div>
  );
}