import { BaseRow, FiltersConfig, FilterType } from '@components/AntGrid/types';
import { ColumnType } from 'antd/es/table';
import { createColumnDefinition } from '../helpers/columnsDefinitions';
import { getIsActiveCell } from '@components/AntGrid/cells/StatusCell';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Hospital } from '@modelTypes/hospital';
import { CheckOutlined } from '@ant-design/icons';
import { FrontendPreviewCell } from '@components/AntGrid/cells/FrontendPreviewCell';
import { IconButtonRenderer } from '../renderers/renderers';
import { message } from 'antd';
import { DeleteCell } from '@components/AntGrid/cells/DeleteCell';
import { dispatchRefreshHospitalsGridEvent } from '@containers/Hospitals/components/HospitalsAntGrid';
import { getHistorySearchParam } from '@helpers/history';
import getIdCell from '@components/AntGrid/cells/IdCell';
import { useHospitalStore } from '@zustandStorage/hospital/hospital';

export type HospitalColumnsNames =
  `${keyof Hospital}Column`
  | 'placeColumn'
  | 'statisticColumn'
  | 'previewColumn'
  | 'eyeIconColumn'
  | 'deleteColumn';

export const hospitalColumnsDefinitions: Partial<Record<HospitalColumnsNames, ColumnType<Hospital>>> = {
  idColumn: createColumnDefinition<Hospital>('#', 'id', { width: 80, render: getIdCell }),
  titleColumn: createColumnDefinition<Hospital>('Title', 'title', { render: getTitleCell }),
  siteColumn: createColumnDefinition<Hospital>('Site', 'website2Url', { render: getSiteCell }),
  urlSlugColumn: createColumnDefinition<Hospital>('Url Slug', 'urlSlug'),
  placeColumn: {
    title: 'Location',
    render: getPlaceCell<Hospital>(),
    sorter: false,
    filtered: false,
  },
  departmentsCountColumn: {
    title: 'Departments Count',
    width: 123,
    render: getDepartmentsCountCell<Hospital>(),
    sorter: false,
    filtered: false,
  },
  scoreColumn: createColumnDefinition<Hospital>('AiroScore', 'score', { width: 127 }),
  googleRatingColumn: createColumnDefinition<Hospital>('Google Rating', 'googleRating', { width: 127 }),
  googleTotalReviewsCountColumn: createColumnDefinition<Hospital>('Google Total Reviews', 'googleTotalReviewsCount', { width: 127 }),
  patientScoreColumn: createColumnDefinition<Hospital>('Patient Score', 'patientScore', { width: 107 }),
  faqColumn: {
    title: 'Has FAQ',
    width: 67,
    render: getHasFAQCell<Hospital>(),
    sorter: false,
    filtered: false,
  },
  statisticColumn: {
    title: 'Has Statistics',
    width: 95,
    render: getHasStatisticsCell<Hospital>(),
    sorter: false,
    filtered: false,
  },
  certificatesCountColumn: {
    title: 'Has Certificates',
    width: 111,
    render: getCertificatesCountCell<Hospital>(),
    sorter: false,
    filtered: false,
  },
  isActiveColumn: createColumnDefinition<Hospital>('Active', 'isActive', {
    render: getIsActiveCell,
    width: 80,
    filtered: false
  }),
  previewColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: getPreviewCell<Hospital>(),
    sorter: false,
    filtered: false,
  },
  eyeIconColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: (value: any, row: Hospital) => {
      return <EditCell record={row} />
    },
    sorter: false,
    filtered: false,
  },
  deleteColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: getDeleteCell(),
    sorter: false,
    filtered: false,
  }
};

export const hospitalColumns: any = [
  hospitalColumnsDefinitions.idColumn,
  hospitalColumnsDefinitions.titleColumn,
  hospitalColumnsDefinitions.siteColumn,
  hospitalColumnsDefinitions.urlSlugColumn,
  hospitalColumnsDefinitions.placeColumn,
  hospitalColumnsDefinitions.departmentsCountColumn,
  hospitalColumnsDefinitions.scoreColumn,
  hospitalColumnsDefinitions.patientScoreColumn,
  hospitalColumnsDefinitions.googleRatingColumn,
  hospitalColumnsDefinitions.googleTotalReviewsCountColumn,
  hospitalColumnsDefinitions.faqColumn,
  hospitalColumnsDefinitions.statisticColumn,
  hospitalColumnsDefinitions.certificatesCountColumn,
  hospitalColumnsDefinitions.isActiveColumn,
  hospitalColumnsDefinitions.previewColumn,
  hospitalColumnsDefinitions.eyeIconColumn,
  hospitalColumnsDefinitions.deleteColumn,
];

export const hospitalFiltersConfig: FiltersConfig = {
  [hospitalColumnsDefinitions.idColumn?.key as string]: { filterType: FilterType.SEARCH },
  [hospitalColumnsDefinitions.titleColumn?.key as string]: { filterType: FilterType.SEARCH },
  [hospitalColumnsDefinitions.siteColumn?.key as string]: { filterType: FilterType.SEARCH },
  [hospitalColumnsDefinitions.urlSlugColumn?.key as string]: { filterType: FilterType.SEARCH },
  [hospitalColumnsDefinitions.placeColumn?.key as string]: { filterType: FilterType.SEARCH },
  [hospitalColumnsDefinitions.googleRatingColumn?.key as string]: { filterType: FilterType.SEARCH },
  [hospitalColumnsDefinitions.googleTotalReviewsCountColumn?.key as string]: { filterType: FilterType.SEARCH },
  // [hospitalColumnsDefinitions.departmentsCountColumn?.key as string]: { filterType: FilterType.SEARCH },
  [hospitalColumnsDefinitions.scoreColumn?.key as string]: { filterType: FilterType.SEARCH },
};

function TitleCell<T extends BaseRow>({ record }: { record: T }) {
  return (
    <Link to={`/hospitals/${record.id}/departments`} title={record.title}>{record.title}</Link>
  );
}

function SiteCell({ value }: { value: string }) {
  return (
    <a href={value} target="_blank" rel="noreferrer">{value}</a>
  );
}

function PlaceCell<T extends BaseRow>({ record }: { record: T }) {
  return (
    <>{`${record.country ? record.country.name : ''} ${record.city ? record.city.name : ''}`}</>
  );
}

function DepartmentsCountCell<T extends BaseRow>({ record }: { record: T }) {
  return (
    <Link to={`/hospitals/${record.id}/departments`}>{record.departmentsCount}</Link>
  );
}

function EditCell({ record }: { record: Hospital }) {
  const history = useHistory();
  const langId = +getHistorySearchParam(history, 'language-id', 1);
  const params = langId > 1 ? `?language-tab=${langId - 1}` : '';

  return IconButtonRenderer<Hospital>(`/hospitals/${record.id}/edit${params}`)
}

function HospitalPreviewCell<T extends BaseRow>({ record }: { record: T }) {

  const { fetchHospitalAccessKey, hospitalAccessKey: { data, loading } } = useHospitalStore()

  const handleGetAccessToken = () => {
    fetchHospitalAccessKey(record.id)
      .then((data) => {
        const url = `${process.env.REACT_APP_CLIENT_URL}/hospitals/${record.urlSlug}?token=${data.accessKey}`;

        if (window !== null) {
          // @ts-ignore
          window.open(url, '_blank', 'noopener,noreferrer').focus();
        }
      });
  };

  return (
    <FrontendPreviewCell
      loading={loading}
      onGetAccessToken={handleGetAccessToken}
    />
  );
}

// function HospitalDeleteCell<T extends BaseRow>({ record }: { record: T }) {
//   const [form] = Form.useForm();
//   const initialDeleteModalState: DeleteModalProps = {
//     open: false,
//     loading: false,
//     title: 'Delete Hospital',
//   };
//   const [deleteModal, onDeleteRow, onCancel] = useDeleteModal(initialDeleteModalState, {
//     deleteThunkAction: deleteHospital,
//     onSuccess: () => {
//       message.success('The hospital has been successfully deleted');
//     },
//     hasNotRelationsContent: () => <div>Hospital has no any relations.</div>,
//     hasRelationsContent: (data: any) => {
//       return (
//         <Box sx={{ mt: 2, mb: 1 }}>
//           <div>If you really want to continue, enter your password.</div>
//         </Box>
//       );
//     }
//   });
//
//   const handleOnSubmitForm = (values: any) => {
//     return onDeleteRow({ id: record.id }, { data: { force: true, ...values } })
//       .catch((e) => {
//         setFormErrors(e, form.getFieldsValue(), form.setFields);
//       });
//   };
//
//   const handleSubmitForm = () => {
//     if (deleteModal.showConfirmForm) {
//       form.submit();
//     } else {
//       onDeleteRow({ id: record.id }, { data: { force: true } });
//     }
//   };
//
//   const handleOnClickDeleteIcon = () => {
//     onDeleteRow({ id: record.id }, {});
//   };
//
//   return (
//     <>
//       <Tooltip title="Delete item">
//         <Spin spinning={!deleteModal.open && deleteModal.loading}>
//           <div onClick={() => handleOnClickDeleteIcon()}>
//             <DeleteIcon style={{ fontSize: 24, cursor: 'pointer' }} />
//           </div>
//         </Spin>
//       </Tooltip>
//       <Modal
//         closable
//         title={deleteModal.title}
//         open={deleteModal.open}
//         onCancel={onCancel}
//         footer={[
//           <Button key="back" onClick={onCancel}>
//             Cancel
//           </Button>,
//           <Button key="clear-by-url" danger type="primary" loading={deleteModal.loading} onClick={handleSubmitForm}>
//             Delete
//           </Button>
//         ]}
//       >
//         <Spin spinning={deleteModal.loading}>
//           {deleteModal.content}
//           {deleteModal.showConfirmForm && (
//             <Form
//               form={form}
//               layout="vertical"
//               onFinish={handleOnSubmitForm}
//               // onFinishFailed={onFinishFailed}
//               autoComplete="off"
//               style={{ marginTop: 24, marginBottom: 34 }}
//             >
//               <Form.Item
//                 name="password"
//                 label="Password"
//                 rules={[{ required: true, message: '"Password" is required' }, { type: 'string', warningOnly: true }]}
//               >
//                 <Input placeholder="Password" type="password" autoComplete="new-password" />
//               </Form.Item>
//             </Form>
//           )}
//         </Spin>
//       </Modal>
//     </>
//   );
// }

function ShowCheckIcon({ value }: { value: boolean }) {
  if (value) {
    return <CheckOutlined />;
  }

  return null;
}

function getTitleCell<T extends BaseRow>() {
  return (value: any, record: T) => {
    return (
      <TitleCell<T> record={record as any} />
    );
  };
}

function getSiteCell<T extends BaseRow>() {
  return (value: any, record: T) => {
    if (typeof value === 'string') {
      return <SiteCell value={value as string} />;
    }

    if (Array.isArray(value)) {
      return (
        <>
          {value.map((item: any, i: number) => (
            <>
              <SiteCell key={i} value={item} />
              {value.length !== i + 1 && <br />}
            </>
          ))}
        </>
      );
    }

    return null;
  };
}

function getPlaceCell<T extends BaseRow>() {
  return (value: any, record: T) => {
    return (
      <PlaceCell<T> record={record as any} />
    );
  };
}

function getDepartmentsCountCell<T extends BaseRow>() {
  return (value: any, record: T) => {
    return (
      <DepartmentsCountCell<T> record={record as any} />
    );
  };
}

function getHasFAQCell<T extends BaseRow>() {
  return (value: any, record: T) => {
    return (
      <ShowCheckIcon value={record.faq} />
    );
  };
}

function getHasStatisticsCell<T extends BaseRow>() {
  return (value: any, record: T) => {
    return (
      <ShowCheckIcon value={record.foundedIn > 0 || record.patientsCount > 0 || record.bedsCount > 0} />
    );
  };
}

function getCertificatesCountCell<T extends BaseRow>() {
  return (value: any, record: T) => {
    return (
      <ShowCheckIcon value={record.certificatesCount > 0} />
    );
  };
}

function getPreviewCell<T extends BaseRow>() {
  return (value: any, record: T) => {
    return (
      <HospitalPreviewCell record={record} />
    );
  };
}

function CustomDeleteCell({ record }: { record: Hospital }) {
  const { deleteHospital } = useHospitalStore()
  return (
    <DeleteCell
      record={record}
      title="Delete Hospital"
      deleteAction={deleteHospital}
      onSuccess={() => {
        dispatchRefreshHospitalsGridEvent();
        message.success('The hospital has been successfully deleted');
      }}
      hasNotRelationsContent={() => <div>Hospital has no any relations.</div>}
      hasRelationsContent={() => {
        return (
          <div style={{}}>
            <div>If you really want to continue, enter your password.</div>
          </div>
        );
      }}
    />
  );
}

function getDeleteCell() {
  return (value: any, record: Hospital) => {
    return (
      <CustomDeleteCell record={record} />
    );
  };
}
