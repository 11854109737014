import { ProcessType } from '@layouts/Notification';
import { Tag } from 'antd';

interface Props {
  type: ProcessType;
}

export default function Type({ type }: Props) {
  return (
    <Tag>
      {
        {
          1: 'Parse Pubmed',
          2: 'Parse Researchgate',
          3: 'Parse Semanticscholar',
          4: 'Parse Google Reviews',
          99: 'Find Unique Publications',
          100: null,
        }[type]
      }
    </Tag>
  );
}
